import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import apiService from './ApiService';
import CustomSelect from './CustomSelect';

const Filters = ({ t, onFilterChange }) => {
  const [search, setSearch] = useState('');
  const [sectors, setSectors] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);

  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    const dropdowndata = async () => {
      const response = await apiService.getDropdownData();
      setSectors(response[2].items);
      setRegions(response[4].items);
      setCountries(response[3].items);
    };
    dropdowndata();
  }, []);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearch(searchValue);
    onFilterChange({ searchValue });
  };

  const handleSectorChange = (selectedOption) => {
    setSelectedSector(selectedOption);
    onFilterChange({ sectorValue: selectedOption ? selectedOption.value : '' });
  };
  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption);
    onFilterChange({ regionValue: selectedOption ? selectedOption.value : '' });
  };
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    onFilterChange({ countryValue: selectedOption ? selectedOption.value : '' });
  };

  const sectorOptions = [
    { value: '', label: t('selectall_filter_label', 'Select All') },
    ...sectors.map((item) => ({
      value: item.itemOption,
      label: item.itemValue,
    })),
  ];

  const regionOptions = [
    { value: '', label: t('selectall_filter_label', 'Select All') },
    ...regions.map((item) => ({
      value: item.itemOption,
      label: item.itemValue,
    })),
  ];

  const countryOptions = [
    { value: '', label: t('selectall_filter_label', 'Select All') },
    ...countries.map((item) => ({
      value: item.itemOption,
      label: item.itemValue,
    })),
  ];

  const clearFiltersClick = async (event) => {
    setSearch('');
    setSelectedSector(null);
    setSelectedRegion(null);
    setSelectedCountry(null);
    onFilterChange({
      searchValue: '',
      sectorValue: '',
      regionValue: '',
      countryValue: '',
      currentPage: 1,
    });
  };

  return (
    <div className="vacancies-filters">
      <div className="filter search-box">
        <label>&nbsp;</label>
        <input
          type="text"
          className="search-input"
          value={search}
          onChange={handleSearchChange}
          placeholder={t('search_placeholder', 'Search specific job titles')}
        />
      </div>
      <div className="filter">
        <label>{t('sector_filter_label', 'Sector')}</label>
        <CustomSelect
          onChange={handleSectorChange}
          options={sectorOptions}
          value={selectedSector}
          id="sector-dropdown"
        />
      </div>
      <div className="filter">
        <label>{t('region_filter_label', 'Region')}</label>
        <CustomSelect
          onChange={handleRegionChange}
          options={regionOptions}
          value={selectedRegion}
          id="region-dropdown"
        />
        <input type="hidden" id="hidden-selected-region-value" value={selectedRegion?.value} />
      </div>
      <div className="filter">
        <label>{t('country_filter_label', 'Country')}</label>
        <CustomSelect
          onChange={handleCountryChange}
          options={countryOptions}
          value={selectedCountry}
          id="country-dropdown"
        />
      </div>
      <div className="filter clear-filters">
        <label>&nbsp;</label>
        <button onClick={clearFiltersClick}>Clear</button>
      </div>
    </div>
  );
};

export default withTranslation()(Filters);
