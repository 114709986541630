import axios from 'axios';

const API_BASE_URL = 'https://api.maersk.com/careers';
const CONSUMER_KEY = 'dShVwzBBG1RJB5y22fP7OrfHxOqJ1y5G';

const apiService = {
  callApi: async (apiUrl) => {
    try {
      let content = await axios.get(apiUrl, {
        headers: {
          'Consumer-Key': CONSUMER_KEY,
        },
      });
      return content.data;
    } catch (err) {
      console.log(err);
    }
  },

  getDropdownData: async () => {
    try {
      const response = await apiService.callApi(`${API_BASE_URL}/formdata?language=EN`);
      return response.Formdata.Entries;
    } catch (err) {
      console.log(err);
    }
  },
  getResults: async (params) => {
    try {
      const response = await apiService.callApi(
        `${API_BASE_URL}/vacancies?language=EN&company=APM Terminals&limit=18${params}`
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
};

export default apiService;
