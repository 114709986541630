import React, { useState } from 'react';
import './vacancies-style.css';
import { withTranslation } from 'react-i18next';
import Title from './Title';
import Filters from './Filters';
import Result from './Result';

const VacanciesSection = () => {
  const [filters, setFilters] = useState({});

  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  return (
    <div>
      <div className="vacancies-navigator">Global Home &gt; Vacancies</div>
      <div className="vacancies-section">
        <Title />
        <Filters onFilterChange={handleFilterChange} />
        <Result {...filters} />
      </div>
    </div>
  );
};

export default withTranslation()(VacanciesSection);
