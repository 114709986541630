import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import apiService from './ApiService';
import Pagination from './Pagination';

const Result = ({ t, searchValue, sectorValue, regionValue, countryValue }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [resultData, setResultData] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue, sectorValue, regionValue, countryValue]);

  useEffect(() => {
    setIsLoading(true);
    const getResults = async () => {
      let queryString = '';
      if (searchValue !== undefined) {
        queryString += `&searchInput=${searchValue}`;
      }
      if (sectorValue !== undefined) {
        queryString += `&category=${sectorValue}`;
      }
      if (regionValue !== undefined) {
        queryString += `&region=${regionValue}`;
      }
      if (countryValue !== undefined) {
        queryString += `&country=${countryValue}`;
      }

      const offset = currentPage - 1;
      queryString += `&offset=${offset}`;

      await apiService
        .getResults(queryString)
        .then((response) => {
          setResultData(response.results);
          setResultCount(response.resultCount);
          setTotalPages(Math.ceil(response.resultCount / 18));
        })
        .then(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setIsLoading(false);
        });
    };
    getResults();
  }, [searchValue, sectorValue, regionValue, countryValue, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="vacancies-result">
      <div className={'content-loader' + (isLoading ? '' : ' is-hidden')}>
        <div></div>
      </div>
      <div className="vacancies-total">
        {t('result_title', 'Results')} {resultCount}
      </div>
      {resultCount === 0 ? (
        <div className="no-result">No Results Found</div>
      ) : (
        <>
          <div className="vacancies-container">
            {resultData.map((data, i) => (
              <div data-id={data.ExternalId} key={i} className="vacancies-card">
                <div className="card-date">{data.Posted}</div>
                <div className="card-title">{data.Title}</div>
                <div className="card-job-title">{data.Category}</div>
                <div className="card-location">
                  {data.City === null ? '' : data.City + ', '} {data.Country}
                </div>
                <a href={data.Url}>
                  <span>Find out more</span>
                </a>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};
export default withTranslation()(Result);
