import React from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { ReactComponent as UpArrow } from '../../assets/images/svgs/up-arrow.svg';
import { ReactComponent as DownArrow } from '../../assets/images/svgs/down-arrow.svg';

const CustomDropdownIndicator = ({ innerProps, selectProps }) => {
  return (
    <div
      {...innerProps}
      style={{
        position: 'relative',
        marginRight: '16px',
        marginTop: selectProps.menuIsOpen ? '-4px' : '0',
      }}
    >
      {selectProps.menuIsOpen ? (
        <UpArrow width="12" height="6" />
      ) : (
        <DownArrow width="12" height="6" />
      )}
    </div>
  );
};

const CustomSelect = ({ t, options, onChange, value, id }) => {
  return (
    <Select
      id={id}
      components={{ DropdownIndicator: CustomDropdownIndicator }}
      value={value}
      onChange={onChange}
      options={options}
      isSearchable={false}
      placeholder={t('selectall_filter_label', 'Select All')}
      styles={{
        control: (provided, state) => ({
          ...provided,
          marginTop: '22px',
          height: '48px',
          borderRadius: '4px',
          border: '1px solid #7a8591',
          boxShadow: 'none',
          backgroundColor: '#fafbfc',
          '&:hover': { borderColor: '#7a8591' },
        }),
        menu: (provided) => ({
          ...provided,
          padding: '0',
          marginTop: '0',
          boxShadow: '0 2px 15px hsla(0,0%,77.3%,.5)',
          border: '0',
        }),
        indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
        option: (provided) => ({
          ...provided,
          height: '42px',
          color: '#3c3c46',
          backgroundColor: '#fff',
          '&:hover': { backgroundColor: '#ffefec' },
        }),
      }}
    ></Select>
  );
};

export default withTranslation()(CustomSelect);
