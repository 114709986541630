import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

const Pagination = ({ t, currentPage, totalPages, onPageChange }) => {
  const [inputPage, setInputPage] = useState(currentPage);

  useEffect(() => {
    setInputPage(currentPage);
  }, [currentPage]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputPage(value);
  };

  const handleEnterPress = () => {
    console.log('handleEnterPress', inputPage);
    // Validate and ensure inputPage is within valid range (1 to totalPages)
    let newPage = parseInt(inputPage);
    if (isNaN(newPage) || newPage < 1) {
      newPage = 1;
    } else if (newPage > totalPages) {
      newPage = totalPages;
    }

    setInputPage(newPage);
    onPageChange(newPage);
  };

  const handleKeyPress = (e) => {
    // Allow only numeric input
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (isNaN(keyValue) || keyValue < '0' || keyValue > '9') {
      e.preventDefault();
    }

    if (parseInt(e.charCode) === 13) {
      handleEnterPress();
    }
  };

  return (
    <div className="vacancies-pagination">
      <div
        className={`pagination-action action-prev ${currentPage === 1 ? ' is-hidden' : ''}`}
        onClick={() => onPageChange(currentPage - 1)}
      ></div>
      <div>
        &nbsp;&nbsp; {t('pagination_label', 'Page')}&nbsp;
        <input
          className="pagination-input"
          type="text"
          value={inputPage}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
        &nbsp;&nbsp;/ {totalPages}&nbsp;&nbsp;
      </div>
      <div
        onClick={() => onPageChange(currentPage + 1)}
        className={`pagination-action action-next ${
          currentPage === totalPages ? ' is-hidden' : ''
        }`}
      ></div>
    </div>
  );
};

export default withTranslation()(Pagination);
