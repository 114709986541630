/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { formdataService } from './vacancies-service';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';

function VacanciesFilters(props) {
  const [sectorValues, setSectorValues] = useState();
  const [regionValues, setRegionValues] = useState();
  const [countryValues, setCountryValues] = useState();
  const [locationValues, setLocationValues] = useState();

  useEffect(() => {
    if (!sectorValues) {
      formdataService()
        .then((res) => {
          setSectorValues(res.Formdata.Entries[2].items);
          setRegionValues(res.Formdata.Entries[4].items);
          setCountryValues(res.Formdata.Entries[3].items);
          setLocationValues(res.Formdata.Entries[4].items);
        })
        .then(() => {
          $(function () {
            $('.vacancies-filters').on('focusout', '.selected-value', function () {
              $(this).removeClass('focused');
            });
            $('.vacancies-filters').on('click', '.selected-value', function () {
              if ($(this).hasClass('focused')) {
                $(this).removeClass('focused');
              } else {
                $(this).addClass('focused');
              }
            });
            $('.vacancies-filters').on('mousedown', '.drop-down-container > div', function () {
              // set values for drop down
              $(this).parent().prev().html($(this).html());
              $(this).parent().prev().attr('selected-index', $(this).attr('index'));
              $(this).parent().prev().attr('selected-value', $(this).attr('value'));
              pushFilters();
            });
          });
        });
    }
  });

  const pushFilters = (search) => {
    // filters value object array
    let prop = {};
    let dropdowns = $('.vacancies-filters .drop-down-container');

    // pick selected value one by one
    $(dropdowns).each(function (i) {
      prop[$(this).prev().prev().html().toLowerCase()] = $(dropdowns[i])
        .prev()
        .attr('selected-value');
    });

    // pick searched input value
    prop.search = search ?? $('.vacancies-filters > .search-box > .search-input').val();

    // push to parent page to filter the results/cards/page number
    props.onChange(prop);
  };

  const handleInput = (event) => {
    pushFilters(event.target.value);
  };

  const handleClick = () => {
    var dropdowns = $('.vacancies-filters .drop-down-container');
    $(dropdowns).each(function (i) {
      let firstChild = $(dropdowns[i]).children()[0];
      $(dropdowns[i]).prev().attr('selected-index', 0);
      $(dropdowns[i]).prev().attr('selected-value', '');
      $(dropdowns[i]).prev().html($(firstChild).html());
    });
    $('.vacancies-filters > .search-box > .search-input').val('');
    props.onChange({});
  };

  return (
    <div className="vacancies-filters">
      <div className="search-box">
        <label>&nbsp;</label>
        <input
          type="text"
          className="search-input"
          placeholder={props.t('search_placeholder', 'Search specific job titles')}
          onChange={handleInput}
        />
      </div>
      <DropDown
        label={props.t('sector_filter_label', 'Sector')}
        object={sectorValues}
        tranlatedContent={props.t}
      />
      <DropDown
        label={props.t('region_filter_label', 'Region')}
        object={regionValues}
        tranlatedContent={props.t}
      />
      <DropDown
        label={props.t('country_filter_label', 'Country')}
        object={countryValues}
        tranlatedContent={props.t}
      />
      {/* <DropDown
        label={props.t('location', 'Location')}
        object={locationValues}
        tranlatedContent={props.t}
      /> */}
      <div className="clear-filters">
        <label>&nbsp;</label>
        <button onClick={handleClick}>{props.t('clear_button_label', 'Clear')}</button>
      </div>
    </div>
  );
}

const DropDown = (content) => {
  return (
    <div className={content.label.toLowerCase() + '-box'}>
      <label>{content.label}</label>
      <button className="selected-value" selected-index="0" selected-value="">
        {content.tranlatedContent('selectall_filter_label', 'Select All')}
      </button>
      <div className="drop-down-container">
        <div index="0" value="">
          {content.tranlatedContent('selectall_filter_label', 'Select All')}
        </div>
        {content.object &&
          content.object.map((data, i) => (
            <div index={i + 1} value={data.itemOption} key={data.itemOption}>
              {data.itemValue}
            </div>
          ))}
      </div>
    </div>
  );
};

export default withTranslation()(VacanciesFilters);
