import React, { useState, useEffect, useCallback } from 'react';
import vacanciesService from './vacancies-service';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';
import VacanciesFilters from './vacancies-filters';

function Vacancies({ t }) {
  const [isLoading, setIsLoading] = useState(true);
  const [vacancies, setVacancies] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [filterObject, setFilterObject] = useState();
  const scrollToResults = () => {
    var top = $('.vacancies-result').offset().top - 100;
    $('html,body').animate({ scrollTop: top }, 1000);
  };

  const prevClick = async (e) => {
    e.preventDefault();
    if (currentPage !== 1) {
      setIsLoading(true);
      performClick(parseInt(currentPage) - 2);
    }
  };

  const submitPageNumber = async (e) => {
    if (e.charCode !== 13 && (e.charCode < 48 || e.charCode > 57)) {
      e.preventDefault();
      return;
    } else if (e.charCode === 13 && currentPage >= 1 && currentPage <= maxPage) {
      setIsLoading(true);
      performClick(parseInt(currentPage) - 1);
    }
  };

  const nextClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    performClick(parseInt(currentPage));
  };

  const performClick = async (offset) => {
    let object = filterObject;
    if (object === undefined || object === null) {
      object = { offset: offset };
    }
    object.offset = offset;
    await getVacancies(object, true, function () {
      setCurrentPage(parseInt(object.offset) + 1);
    });
  };

  function UpdateVacancies(vacanciesObject, scrollView) {
    setVacancies(vacanciesObject);
    setMaxPage(Math.ceil(vacanciesObject.resultCount / 18));
    setIsLoading(false);
    if (scrollView) {
      scrollToResults();
    }
  }

  const getVacancies = async (object, scrollView, callBack = () => {}) => {
    let params = '';
    params += updateUrl('offset', object.offset);
    params += updateUrl('searchInput', object.search);
    params += updateUrl('category', object.sector);
    params += updateUrl('region', object.region);
    params += updateUrl('country', object.country);
    await vacanciesService(params).then((res) => {
      UpdateVacancies(res, scrollView);
      callBack();
    });
  };

  function updateUrl(paramName, paramValue) {
    if (paramValue !== undefined) {
      return `&${paramName}=${paramValue}`;
    } else {
      return `&${paramName}=`;
    }
  }

  const handleCallback = useCallback(async (object) => {
    object.offset = 0;
    setFilterObject(object);
    setIsLoading(true);
    setCurrentPage(1);
    await getVacancies(object, false);
  });

  useEffect(() => {
    if (!vacancies) {
      getVacancies({ offset: 0 }, false);
    }
  });

  return (
    <div className="vacancies-result">
      <div className={'content-loader' + (isLoading ? '' : ' is-hidden')}>
        <div></div>
      </div>
      <VacanciesFilters onChange={handleCallback}></VacanciesFilters>
      <div className="vacancies-total">
        {t('results_label', 'Results')} {vacancies && vacancies.resultCount}
      </div>
      <div className="vacancies-container">
        {vacancies &&
          vacancies.results.map((data, i) => (
            <div data-id={data.ExternalId} key={i} className="vacancies-card">
              <div className="card-date">{data.Posted}</div>
              <div className="card-title">{data.Title}</div>
              <div className="card-job-title">{data.Category}</div>
              <div className="card-location">
                {data.City === null ? '' : data.City + ', '} {data.Country}
              </div>
              <a href={data.Url}>
                <span>Find out more</span>
              </a>
            </div>
          ))}
      </div>
      {vacancies && vacancies.resultCount === 0 ? (
        <div className="no-result">No Results Found</div>
      ) : (
        <div className="vacancies-pagination">
          <div
            className={
              'action-item action-prev' + (parseInt(currentPage) === 1 ? ' is-hidden' : '')
            }
            onClick={prevClick}
          ></div>
          <div>
            &nbsp;&nbsp; {t('pagination_label', 'Page')}&nbsp;
            <input
              className="action-input"
              type="text"
              value={currentPage}
              onChange={(e) => setCurrentPage(e.target.value)}
              onKeyPress={submitPageNumber}
            />
            &nbsp;&nbsp;/ {maxPage}&nbsp;&nbsp;
          </div>
          <div
            className={
              'action-item action-next' + (parseInt(currentPage) >= maxPage ? ' is-hidden' : '')
            }
            onClick={nextClick}
          ></div>
        </div>
      )}
    </div>
  );
}

export default withTranslation()(Vacancies);
