import axios from 'axios';

const CAREERS_API_BASE_URL = process.env.REACT_APP_CAREERS_API_BASE_URL;
const CAREERS_API_CONSUMER_KEY = process.env.REACT_APP_CAREERS_API_CONSUMER_KEY;

async function callApi(apiUrl) {
  try {
    let content = await axios.get(apiUrl, {
      headers: {
        'Consumer-Key': CAREERS_API_CONSUMER_KEY,
      },
    });
    return content.data;
  } catch (err) {
    console.log(err);
  }
}

export const formdataService = () => {
  let url = `${CAREERS_API_BASE_URL}/formdata?language=EN`;
  return callApi(url);
};

const vacanciesService = async (params) => {
  let url = `${CAREERS_API_BASE_URL}/vacancies?language=EN&company=APM%20Terminals&limit=18${params}`;
  return callApi(url);
};

export default vacanciesService;
